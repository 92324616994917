import React from 'react'
import T from 'prop-types'
import { Transition as ReactTransition, TransitionGroup } from 'react-transition-group'

export default function Transition({
  children,
  updateKey,
  className,
  enter = 1000,
  exit = 1000,
  node = 'div',
  ...props
}) {
  const Node = node

  return (
    <TransitionGroup component={null}>
      <ReactTransition key={updateKey} timeout={{ enter, exit }}>
        {(status) => (
          <Node className={className} data-animation={status} {...props}>
            {children}
          </Node>
        )}
      </ReactTransition>
    </TransitionGroup>
  )
}

Transition.propTypes = {
  children: T.any.isRequired,
  updateKey: T.string.isRequired,
  className: T.string,
  enter: T.number,
  exit: T.number,
  node: T.string,
}

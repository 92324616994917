/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, forwardRef } from 'react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'

interface BlindheimSuperLinkzProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  as?: string
  href: string | { pathname: string; query?: Record<string, any> }
  children: ReactNode
}

const Link = forwardRef<HTMLAnchorElement, BlindheimSuperLinkzProps>(function BlindheimSuperLinkz(
  { children, as, href, ...aProps },
  ref,
) {
  const router = useRouter()
  let linkProps

  if (router?.query?.referer === href) {
    linkProps = { as, href }
  } else if (typeof href !== 'string' && href?.query) {
    linkProps = {
      as,
      href: {
        ...href,
        query: {
          referer: router.asPath,
          ...href.query,
        },
      },
    }
  } else {
    linkProps = {
      as: as || href,
      href: { pathname: href, query: { referer: router.asPath } },
    }
  }

  return (
    <NextLink {...linkProps} legacyBehavior>
      <a {...aProps} ref={ref}>
        {children}
      </a>
    </NextLink>
  )
})

export default Link

import '../../styles/styles.scss'

import * as Fathom from 'fathom-client'
import T from 'prop-types'
import React, { useEffect } from 'react'

import { AmplitudeContextProvider } from '../components/Amplitude'
import AuthWrapper from '@/components/AuthWrapper'
import Layout from '@/components/Layout'
import { getSettings } from '../api'
import { settings as settingsStore } from '../lib/store'
import { useAtom } from 'jotai'

export default function MyApp({ Component, pageProps, router, settings }) {
  const [, setSettingsAtom] = useAtom(settingsStore)

  setSettingsAtom(settings)

  useEffect(() => {
    Fathom.load('AMGDRFCH', {
      excludedDomains: ['localhost:3000'],
    })

    // Store the scrollpos from previous page for use in the page-transition
    const handleRouteChange = () => {
      Fathom.trackPageview()

      const root = document.documentElement
      const scrollPos =
        window.scrollY ||
        window.pageYOffset ||
        document.body.scrollTop ||
        document.documentElement.scrollTop
      root.style.setProperty('--scroll-pos', `-${scrollPos}px`)
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events])

  // TODO: Make this more scalable for other things
  const skipLayout = ['/rullefortelling/ki', '/rullefortelling/tjekdet'].includes(router.route)
  const Wrapper = skipLayout ? React.Fragment : Layout

  return (
    <AmplitudeContextProvider>
      <AuthWrapper>
        <Wrapper>
          <Component {...pageProps} key={router.route} />
        </Wrapper>
      </AuthWrapper>
    </AmplitudeContextProvider>
  )
}

MyApp.propTypes = {
  Component: T.func.isRequired,
  pageProps: T.objectOf(T.any),
  router: T.object.isRequired,
}

MyApp.getInitialProps = async ({ preview = false }) => {
  const settings = await getSettings(preview)
  return { settings }
}

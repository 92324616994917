import React, { useRef } from 'react'
import T from 'prop-types'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'
import useLayoutEffect from '../../hooks/useLayoutEffect'
import Link from '../Link'

const bem = new BEMHelper(styles)

export default function Brand({ href = '/', className }) {
  const brandRef = useRef()

  useLayoutEffect(() => {
    const root = document?.documentElement
    const height = brandRef?.current?.offsetHeight
    if (height) {
      root.style.setProperty('--logo-height', `${height}px`)
    }
  })

  return (
    <Link href={href} {...bem('', '', className)} ref={brandRef}>
      Tenk
    </Link>
  )
}

Brand.propTypes = {
  href: T.string,
  className: T.string,
}

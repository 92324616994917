import { useState, useEffect } from 'react'
import { Session } from '@supabase/supabase-js'
import { useAtom } from 'jotai'

import supabase from '@/lib/supabase'
import { isLoggedIn } from '@/lib/store'

export default function AuthWrapper({ children }: { children: React.ReactNode }) {
  const [, setIsLoggedIn] = useAtom(isLoggedIn)
  const [, setLoading] = useState(true)
  const [session, setSession] = useState<Session | null>(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data }) => {
      const { session } = data
      setLoading(false)
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => setSession(session))

    return () => subscription.unsubscribe()
  }, [])

  useEffect(() => {
    setIsLoggedIn(!!session)
  }, [session, setIsLoggedIn])

  return children
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react'

const usePrevious = <T extends any[]>(callback: (prev: T) => void, deps: T): void => {
  const callbackRef = useRef<null | ((prev: T) => void)>(null)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  const depsRef = useRef<null | T>(null)

  const [initial, setInitial] = useState(true)

  useEffect(() => {
    if (!initial && depsRef.current !== null && callbackRef.current !== null) {
      callbackRef.current(depsRef.current)
    }

    depsRef.current = deps
    setInitial(false)
  }, deps)
}

export default usePrevious

import imageUrlBuilder from '@sanity/image-url'
import { createClient } from 'next-sanity'

export const projectId = process.env.SANITY_PROJECT_ID || 'ksbd2rdi'
export const dataset = process.env.SANITY_DATASET || 'production'

const config = {
  dataset,
  projectId,
  useCdn: process.env.NODE_ENV === 'production',
  token: process.env.SANITY_API_TOKEN,
  apiVersion: '2021-03-25', // use a UTC date string
  // useCdn == true gives fast, cheap responses using a globally distributed cache.
  // Set this to false if your application require the freshest possible
  // data always (potentially slightly slower and a bit more expensive).
}

// Set up the client for fetching data in the getProps page functions
export const client = createClient({
  ...config,
  perspective: 'published',
})

// Set up a preview client with serverless authentication for drafts
export const previewClient = createClient({
  ...config,
  useCdn: false,
  perspective: 'previewDrafts',
})

/* module.exports.getFileUrl = (reference) => {
  const [, assetId, ext] = (reference || '').split('-')

  if (!reference || !assetId || !ext) {
    return null
  }

  return `https://cdn.sanity.io/files/${projectId}/${dataset}/${assetId}.${ext}`
} */

function DummyBuilder() {}
;['width', 'height', 'format', 'saturation', 'url'].forEach(
  (prop) =>
    (DummyBuilder.prototype[prop] = function () {
      return this
    }),
)

// module.exports.getImageUrl = (source) => createImageUrlBuilder(config).image(source)
export const getImageUrl = (source) => {
  if (!source?.asset) {
    return new DummyBuilder()
  }
  try {
    return imageUrlBuilder(config).image(source)
  } catch (e) {
    return new DummyBuilder()
  }
}

// Helper function for easily switching between normal client and preview client
export const getClient = (usePreview) => (usePreview ? previewClient : client)

import { useRouter } from 'next/router'
import { useEffect, useRef, useState, type FormEvent } from 'react'

import Link from 'next/link'

import Brand from '@/components/Brand'
import Icon from '@/components/Icon'
import Transition from '@/components/Transition'

import usePrevious from '@/hooks/usePrevious'
import useSettings from '@/hooks/useSettings'

import BEMHelper from '@/helpers/bem'
import styles from './Styles.module.scss'
const bem = BEMHelper(styles)

interface Props {
  footerVisible: boolean
}

const FALLBACK = [
  { _key: '1', title: 'Hjem', href: '/' },
  { _key: '2', title: 'Om oss', href: '/om-oss' },
  { _key: '3', title: 'Ressurser', href: '/ressurser' },
  { _key: '4', title: 'For foreldre', href: '/foreldre' },
]

export default function Menu({ footerVisible }: Props) {
  const settings = useSettings()
  const [expanded, setExpanded] = useState(false)
  const router = useRouter()
  const showSearch = !router.pathname.startsWith('/sok') && router.pathname !== '/'

  const menu = (settings?.menu || FALLBACK).filter((item) => item.href !== '/sok')

  const toggle = () => {
    setExpanded((val) => !val)
  }

  useEffect(() => {
    if (router.asPath !== '/') {
      setExpanded(false)
    }
  }, [router.asPath])

  return (
    <>
      <Transition updateKey={expanded ? 'open' : 'closed'} {...bem('animation')}>
        {expanded && (
          <>
            <nav {...bem('menu')}>
              <div {...bem('content')}>
                {menu.map((link) => (
                  <Link key={link._key} href={link.href} {...bem('item')}>
                    {link.title}
                  </Link>
                ))}
                <Search large />
              </div>
            </nav>
            <button type="button" onClick={toggle} aria-label="Lukk meny" {...bem('backdrop')} />
          </>
        )}
      </Transition>

      <div {...bem('', { hidden: footerVisible })}>
        <span {...bem('brand')}>
          <Brand />
        </span>
        <button type="button" {...bem('toggle')} onClick={toggle}>
          <MenuIcon expanded={expanded} />
        </button>

        {showSearch && <Search hidden={expanded} />}
      </div>
    </>
  )
}

function Search({ large, hidden }: { large?: boolean; hidden?: boolean }) {
  const router = useRouter()
  const searchRef = useRef<HTMLInputElement>(null)

  const updateUrl = (q: string) => {
    router.replace({
      pathname: '/',
      query: { q },
    })
  }

  const handleSearch = (event: FormEvent) => {
    event.preventDefault()
    const formData = new FormData(event.target as HTMLFormElement)
    const searchValue = formData.get('search')

    if (searchValue !== null) {
      searchRef.current?.blur()
      updateUrl(searchValue.toString())
    }
  }
  return (
    <form {...bem('search', { small: !large, large, hidden })} onSubmit={handleSearch}>
      <button type="submit" {...bem('submit')}>
        <Icon icon="search" {...bem('icon')} />
      </button>
      <input type="search" placeholder="Søk" name="search" ref={searchRef} {...bem('input')} />
    </form>
  )
}

function MenuIcon({ expanded }: { expanded: boolean }) {
  const svgRef = useRef<SVGSVGElement>(null)
  const line1 = ['2,2 16,16', '0,3 18,3']
  const line2 = ['9,9 9,9', '0,9 18,9']
  const line3 = ['2,16 16,2', '0,15 18,15']

  usePrevious<[boolean]>(
    ([prevIsOpen]) => {
      const animations = svgRef.current?.querySelectorAll('animate')

      if (animations && prevIsOpen !== expanded) {
        animations.forEach((animation) => {
          animation.setAttribute('attributeName', 'points')
          animation.setAttribute('dur', '200ms')
          animation.setAttribute('calcMode', 'spline')
          animation.setAttribute('keySplines', ' 0.19, 1, 0.22, 1')
          animation.setAttribute('fill', 'freeze')
          animation.removeAttribute('begin')

          animation.beginElement()
        })
      }
    },
    [expanded],
  )

  return (
    <svg viewBox="0 0 18 18" width="18px" height="18px" {...bem('menu-icon')} ref={svgRef}>
      <polyline points={line1[1]} {...bem('menu-line')}>
        <animate
          id="line1"
          begin="0s"
          from={expanded ? line1[1] : line1[0]}
          to={expanded ? line1[0] : line1[1]}
        />
      </polyline>
      <polyline points={line2[1]} {...bem('menu-line')}>
        <animate
          id="line2"
          begin="0s"
          from={expanded ? line2[1] : line2[0]}
          to={expanded ? line2[0] : line2[1]}
        />
      </polyline>
      <polyline points={line3[1]} {...bem('menu-line')}>
        <animate
          id="line3"
          begin="0s"
          from={expanded ? line3[1] : line3[0]}
          to={expanded ? line3[0] : line3[1]}
        />
      </polyline>
    </svg>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { atom } from 'jotai'

export const isLoggedIn = atom<boolean>(false)

export const settings = atom<{
  menu?: {
    _key: string
    title: string
    href: string
  }[]
  searchPlaceholders?: string[]
  footer?: any
}>()

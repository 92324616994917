import { useState, useEffect, useRef } from 'react'

// const intersections = [
//   0,
//   0.05,
//   0.1,
//   0.15,
//   0.2,
//   0.25,
//   0.3,
//   0.35,
//   0.4,
//   0.45,
//   0.5,
//   0.55,
//   0.6,
//   0.65,
//   0.7,
//   0.75,
//   0.8,
//   0.85,
//   0.9,
//   0.95,
//   1,
// ]
// const intersections = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
// const intersections = [0, 0.2, 0.4, 0.6, 0.8, 1]
// const intersections = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.8, 1]
const intersections = [0, 0.5, 1]

export default function useIntersection(ref, options = {}, onlyOnce) {
  const observer = useRef()
  const [intersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const currentRef = ref.current // Copy ref.current to a variable

    if (!currentRef) {
      return
    }

    observer.current = new IntersectionObserver(
      ([entry]) => {
        const show = entry.intersectionRatio > 0.4
        setIntersecting(show)

        if (show && onlyOnce) {
          observer.current.unobserve(currentRef) // Use the copied variable
        }
      },
      {
        threshold: intersections,
        ...options,
      },
    )

    observer.current.observe(currentRef) // Use the copied variable

    return () => observer.current.unobserve(currentRef) // Use the copied variable
  }, [ref, onlyOnce, options]) // Use ref instead of ref.current

  return intersecting
}

import { createContext, useEffect } from 'react'
import { init, track } from '@amplitude/analytics-browser'
import { dataset } from '@/api/sanity'

const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY

const trackAmplitudeEvent = (eventName, eventProperties) => {
  track(eventName, eventProperties)
}

export const AmplitudeContext = createContext<{ trackAmplitudeEvent?: typeof trackAmplitudeEvent }>(
  {},
)

export function AmplitudeContextProvider({ children }) {
  const isProduction = dataset === 'production'

  useEffect(() => {
    if (!AMPLITUDE_API_KEY && isProduction) {
      console.error('❌ Amplitude API key is missing')
    }

    if (!isProduction) {
      console.info('🚫 Amplitude is disabled in development')
    }

    AMPLITUDE_API_KEY &&
      isProduction &&
      init(AMPLITUDE_API_KEY, undefined, {
        autocapture: true,
        defaultTracking: {
          sessions: true,
          pageViews: true,
        },
        serverZone: 'EU',
      })
  }, [isProduction])

  if (!isProduction) {
    return <>{children}</>
  }

  return (
    <AmplitudeContext.Provider value={{ trackAmplitudeEvent }}>
      {children}
    </AmplitudeContext.Provider>
  )
}
